<template>
  <el-dialog
    :title="this.title + '历史财报'"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <table border="1">
      <thead>
        <tr>
          <th>标题</th>
          <th>文件</th>
          <th>操作人</th>
          <th>上传时间</th>
        </tr>
      </thead>
      <tbody v-if="getList.length">
        <tr v-for="(item, index) in getList" :key="index">
          <td>{{ item.title }}</td>

          <td class="Line" @click="Download(item)">
            {{ JSON.parse(item.files)[0].FilesName }}
          </td>
          <td>{{ item.createrName }}</td>
          <td>{{ item.createTime ? item.createTime.substr(0, 10) : "" }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4">暂无数据</td>
        </tr>
      </tbody>
    </table>
  </el-dialog>
</template>

<script>
import { selectExport, download } from "@/api/ChargeUp";
export default {
  data() {
    return {
      dialogVisible: true,
      getList: [], //列表
      title: "", //这是名字
      baseUrl: window.globalUrl.BASE_API,
    };
  },

  methods: {
    // 取消弹窗按钮
    Cancel() {
      this.$emit("closeDialog", false);
    },
    //点击关闭弹窗
    handleClose() {
      this.$emit("closeDialog", false);
    },
    async initId(obj) {
      console.log(obj, "查看");
      let params = {
        userId: obj.userId,
        identityId: obj.identityId,
      };
      let res = await selectExport(params);
      this.getList = res.data;
      console.log(this.getList);
      this.title = obj.userName;
    },
    //财报下载
    async Download(item) {
      let itemList = JSON.parse(item.files)[0];
      console.log(itemList, "查看");
      let res = await download({ ossFilePath: itemList.imgUrl });
      if (res) {
        let blob = res;
        const fileName = itemList.FilesName;
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        // "|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
        let href = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
          })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  tr {
    td {
      height: 50px;
      text-align: center;
    }
    .Line {
      text-decoration: underline;
      color: #1e1ec6;
    }
  }
}
</style>
