<template>
  <div>
    <h3>财务报表上传</h3>
    <!-- 展示财务报表弹窗 -->
    <history v-if="showHis" @closeDialog="closeDialog" ref="ShowHis" />
    <!-- 上传财务报表 -->
    <FinancialUp v-if="isUp" @closeDialog2="closeDialog2" ref="IsUp" />
    <!-- 根据条件搜索-->
    <div class="searchItem">
      <el-form
        :model="form"
        label-position="left"
        label-width="80px"
        inline
        ref="form"
      >
        <el-form-item label="用户名：">
          <el-input
            style="width: 190px"
            v-model="form.userName"
            placeholder="用户名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="上传时间：">
          <el-date-picker
            v-model="form.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            value-format="yyyy-MM-dd"
            @change="changeTime"
          >
          </el-date-picker>
        </el-form-item>

        <div>
          <el-button class="query-btn" type="primary" @click="search"
            >搜索</el-button
          >
        </div>
      </el-form>
    </div>
    <!-- table列表 -->
    <div class="TableList">
      <table-page
        :data="tableData"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :loading="loading"
        :border="true"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
      >
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="history(scope.row)"
            >记录</el-button
          >
          <el-button type="text" size="small" @click="Upload(scope.row)"
            >上传财报</el-button
          >
        </template>
      </table-page>
    </div>
  </div>
</template>

<script>
import { uploadFinancial } from "../tableColumn";
import TablePage from "@/components/table/table-page";
import history from "./component/historyList.vue";
import FinancialUp from "./component/FinancialUp";
import { selectUserExport } from "@/api/ChargeUp";
export default {
  data() {
    return {
      form: {
        userName: "", //用户名
        time: ["", ""], //上传时间
      },
      tableData: [], //table数据
      columns: uploadFinancial, //table表头
      query: {
        pnum: 1,
        psize: 10,
      }, //分页

      total: 0, //数量
      loading: false, //加载状态
      showHis: false, //展示财务报表弹窗
      isUp: false, //是否展示上传弹窗
      startTime: "",
      endTime: "",
    };
  },
  components: {
    TablePage,
    history,
    FinancialUp,
  },
  mounted() {
    //列表
    this.getList();
  },

  methods: {
    //筛选时间
    changeTime(val) {
      this.startTime = val[0];
      this.endTime = val[1];
      // this.initData();
      console.log(this.startTime, this.endTime);
    },
    //获取数据列表
    async getList() {
      let params = {
        psize: 10,
        pnum: 1,
      };
      let res = await selectUserExport(params);
      this.tableData = res.data;
      this.total = res.dataNum;
    },

    //查看历史记录
    history(obj) {
      this.showHis = true;
      this.$nextTick(() => {
        this.$refs.ShowHis.initId(obj);
      });
    },
    //上传文件
    Upload(obj) {
      this.isUp = true;
      this.$nextTick(() => {
        this.$refs.IsUp.init(obj);
      });
    },
    //关闭查看历史记录
    closeDialog(showHis) {
      this.showHis = showHis;
    },
    //关闭上传弹窗
    closeDialog2(isUp) {
      this.isUp = isUp;
    },
    //分页单页切换
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.getList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        pnum: 1,
        psize: size,
      };
      this.recordInquiry();
    },
    async search() {
      let params = {
        ...this.query,
        ...this.form,
        startTime: "",
        endTime: "",
      };
      let res = await selectUserExport(params);
      this.tableData = res.data;
      this.total = res.dataNum;
    },
  },
};
</script>

<style lang="less" scoped>
/*根据条件搜索*/
.searchItem {
  .el-form {
    width: 100%;
    display: flex;
  }

  /deep/.el-form-item {
    width: 25% !important;
    .el-input {
      width: 200px;
    }
    .el-form-item__label {
      width: 100px !important;
    }
    .el-form-item__content {
      width: 200px;
    }
    .el-date-editor {
      width: 200px;
    }
  }
}
</style>
